import { FormForCompanies } from "../components/FormForCompanies/FormForCompanies"
import { HeaderCompanies } from "../components/Header/HeaderCompanies"
import { Reference } from "../components/Reference/Reference"
import { UslugiTab } from "../components/UslugiTab/UslugiTab"

export const ForCompanies = () => {
  return (
    <>
      <HeaderCompanies />
      <UslugiTab />
      <FormForCompanies />
      <Reference />
    </>
  )
}