import React from 'react';
import './Onas.styles.scss';

const Onas = () => {
    return (
        <div className='onas-wrapper' id='onas'>
            <div className='back-img-top'></div>
            <div className='container'>
                <div className='onas-container'>
                    <div className="img-cleaning-woman"></div>
                    <div className="oNasInformation">
                        <h3 className='onas-title'>O NAS</h3>
                        <ul className="oNasText">
                            <li>Zapewniamy wszystkie niezbędne wysokiej jakości środki czystości oraz sprzęt;</li>
                            <li>Nie musisz udostępniać własnego odkurzacza ani chemii;</li>
                            <li>Od razu widzisz ostateczną cenę;</li>
                            <li>Płatność gotówką lub kartą po zakończeniu usługi;</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='back-img-bottom'></div>
        </div>
    );
};

export default Onas;