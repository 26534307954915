import { useState, useRef } from 'react';
import { ModalReglament } from '../Modal/ModalReglament';
import { ModalPrivacyPolicy } from '../Modal/ModalPrivacyPolicy';
import './FormForCompanies.scss';
import { FileInput } from '../FileInput/FileInput';
import { postOrderCompanies } from '../helpers/Api';
import { Modal } from '../Modal/Modal';

export const FormForCompanies = () => {

  const [valueText, setValueText] = useState('');
  const textareaRef = useRef(null);

  const handleChangeText = (event) => {
    setValueText(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeAgree = () => {
    setIsChecked(!isChecked);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const [isOpenModalPrivacy, setIsOpenModalPrivacy] = useState(false);
  const handleOpenModalPrivacy = () => setIsOpenModalPrivacy(true);
  const handleCloseModalPrivacy = () => setIsOpenModalPrivacy(false);

  const [fields, setFields] = useState({
    firstName: '',
    email: '',
    phone: '',
  });

  const handleFirstNameChange = (event) => {
    setFields({ ...fields, firstName: event.target.value });
  };

  const handleEmailChange = (event) => {
    setFields({ ...fields, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setFields({ ...fields, phone: event.target.value });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const handleCloseErrorModal = () => setErrorModalOpen(false);
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    window.location.reload();
  };

  const formMess = () => {
    let formMessVar = '';
    // if (messSend) {
    //   formMessVar = 'Wiadomość wysłana. Skontaktujemy się z Tobą wkrótce.';
    // } else if (messSendMistake) {
    //   formMessVar = 'Serwis jest tymczasowo niedostępny. Proszę spróbować ponownie później.';
    // } else {
    //   if (validateForm()) {
    //     formMessVar = 'mogę wysłać';
    //   } else {
    //     formMessVar = 'wypełnij pola';
    //   }
    // }
    if (validateForm()) {
      formMessVar = 'mogę wysłać';
    } else {
      formMessVar = 'wypełnij pola';
    }
    return formMessVar;
  }

  const resetSetFields = () => {
    setFields({
      firstName: '',
      email: '',
      phone: '',
    });
    setValueText('');
    setFile('');
    setIsChecked(false);
  };

  const validateEmail = () => {
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (pattern.test(fields.email)) {
      return true;
    } else {
      return false;
    }
  }

  const validateForm = () => {
    if (isChecked && validateEmail() && fields.firstName !== '' && fields.phone !== '' ) {
      return 1;
    } else {
      return 0;
    }
  }

  const mask = (event) => {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 4) event.preventDefault();
    let matrix = "+48 ___ ___ ___",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a
      });
    i = new_value.indexOf("_");
    if (i !== -1) {
      i < 5 && (i = 4);
      new_value = new_value.slice(0, i);
    }
    let reg = matrix.substr(0, event.target.value.length).replace(/_+/g,
      function (a) {
        return "\\d{1," + a.length + "}"
      }).replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (!reg.test(event.target.value) || event.target.value.length < 5 || (keyCode > 47 && keyCode < 58)) event.target.value = new_value;
    if (event.type === "blur" && event.target.value.length < 3) event.target.value = "";
  }

  const [file, setFile] = useState('');

  const submitForm = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    // formData.append('attachment', file);
    if (validateForm()) {
      postOrderCompanies(formData).then((res) => {
        if (res.status === 200) {
          // setMessSend(true);
          // setMessSendMistake(false);
          // setTimeout(() => {
          //   setMessSend(false);
          //   // window.location.reload();
          // }, 2000)
          setSuccessModalOpen(true);
          resetSetFields();
        }
      }
      )
        .catch(error => {
          if (error) {
            // setMessSendMistake(true);
            setErrorModalOpen(true)
          }
        })
    }
  }

  return (
    <section className="section-contact" id="skontaktuj">
      <div className="container">
        <div className="section-contact__wrapp">
          <h4>Skontaktuj się z nami</h4>
          <a href={"mailto:bialoklining@gmail.com"} className="section-contact__link">
            <span>Email: bialoklining@gmail.com</span>
          </a>
          <form className='section-contact__form' onSubmit={submitForm}>
            <input className='section-contact__form-input' name='name' type="text" placeholder="Imię*" value={fields.firstName} onChange={handleFirstNameChange} onKeyPress={handleKeyPress} />
            <input className='section-contact__form-input' name='email' type="email" placeholder="E-mail*" value={fields.email} onChange={handleEmailChange} onKeyPress={handleKeyPress} />
            <input className='section-contact__form-input'
              name='phone'
              type="tel"
              placeholder="Telefon*"
              value={fields.phone}
              onChange={handlePhoneChange}
              onKeyPress={handleKeyPress}
              onInput={mask}
              onFocus={mask}
              onBlur={mask}
              onKeyDown={mask} />
            <textarea
              ref={textareaRef}
              name='text'
              value={valueText}
              onChange={handleChangeText}
              className="section-contact__textarea"
              placeholder='Text'
            />

            <FileInput
              fileName={file}
              setFileName={setFile}
              name='attachment'
            />

            <div className="check">
              <input className='check-form' type="checkbox" checked={isChecked} onChange={handleCheckboxChangeAgree} />
              <span>Zapoznałem się z <button onClick={handleOpenModal} className='modal-btn-agree' type='button'>Regulaminem</button>
                oraz <button onClick={handleOpenModalPrivacy} className='modal-btn-agree' type='button'>Polityką prywatności</button>
                i akceptuję ich warunki</span>
            </div>
            <div className="form__btn">
              <input type="submit" name="form-action" value="Wyślij" className={validateForm() ? 'submitAvalible' : 'submitDisables'} />
            </div>
            <div className="val-mess">{formMess()}</div>
          </form>
        </div>
      </div>
      {errorModalOpen && (
        <div className='modal-form-wrapp'>
          <Modal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
            <div className='form-modal-text'>
              <span>Serwis jest tymczasowo niedostępny. Proszę spróbować ponownie później.</span>
              <button onClick={handleCloseErrorModal}>Dobrze</button>
            </div>
          </Modal>
        </div>
      )}
      {successModalOpen && (
        <div className='modal-form-wrapp'>
          <Modal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
            <div className='form-modal-text'>
              <span>Wiadomość wysłana. Skontaktujemy się z Tobą wkrótce.</span>
              <button className='form-modal-btn-success' onClick={handleCloseSuccessModal}>Dobrze</button>
            </div>
          </Modal>
        </div>
      )}
      <ModalReglament isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} handleCloseModal={handleCloseModal} />
      <ModalPrivacyPolicy isOpenModal={isOpenModalPrivacy} setIsOpenModal={setIsOpenModalPrivacy} handleCloseModal={handleCloseModalPrivacy} />
    </section>
  )
}