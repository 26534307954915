import React, { useEffect } from "react";
import { FurnitureCleaningIcon } from "../../Icon/FurnitureCleaningIcon";
import { getFurniture } from "../../helpers/Api";
import dust from '../../../assets/images/dust.svg';

export function FurnitureCleaning({ furniture, setFurniture, priceFurniture, setPriceFurniture }) {
    useEffect(() => {
        getFurniture().then((data) => {
            setFurniture(data);
        });
    }, [setFurniture]);

    const handleChangeFurniture = (event, optionId) => {
        const value = event.target.value;
        setPriceFurniture((prevValues) => ({ ...prevValues, [optionId]: value }));
    };

    const isInputEmpty = Object.values(priceFurniture).every(value => !value);

    return (
        <div className="__osobiste__block __block__mebel">
            <div className="__osobiste__block__left">
                <div className={`__osobiste__block__left__main ${!isInputEmpty ? 'block__active' : ''}`}>
                    <div className="osobiste__block-number">4</div>
                    {!isInputEmpty && (
                        <div className="osobiste__block-v">
                            <svg className="osobiste__block-v-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                                <path d="M1.29394 4.50721C1.51473 4.28649 1.81414 4.1625 2.12634 4.1625C2.43853 4.1625 2.73795 4.28649 2.95874 4.50721L7.12192 8.6704L15.4459 0.345208C15.5552 0.235818 15.685 0.149031 15.8279 0.0897997C15.9707 0.0305688 16.1239 5.47404e-05 16.2785 7.35834e-08C16.4332 -5.45932e-05 16.5863 0.0303512 16.7292 0.0894811C16.8721 0.148611 17.0019 0.235307 17.1113 0.34462C17.2207 0.453932 17.3075 0.58372 17.3667 0.726573C17.426 0.869426 17.4565 1.02255 17.4565 1.17719C17.4566 1.33184 17.4262 1.48498 17.3671 1.62788C17.3079 1.77077 17.2212 1.90062 17.1119 2.01001L7.12192 12L1.29394 6.17202C1.07321 5.95123 0.949219 5.65181 0.949219 5.33961C0.949219 5.02742 1.07321 4.728 1.29394 4.50721Z" fill="white" />
                            </svg>
                        </div>
                    )}
                    <FurnitureCleaningIcon />
                    <div className="__osobiste__block__left__main__h">czyszczenie mebli</div>
                </div>
            </div>
            <div className="__osobiste__block__right">
                <div className="mycie_okien">
                    {furniture.map((option) => (
                        <div key={option.id}
                            className="mycie_okien_item">
                            <h6>{option.name}</h6>
                            <div className="image-wrapp">
                                {option.image !== '/image/no-image' ? (
                                    <img src={option.image} alt={option.name} />
                                ) : (
                                    <img src={dust} alt="" />
                                )}
                            </div>
                            <div
                                className={`mycie_okien_item__after ${option.name === 'Wykładzina dywanowa' ? 'after-m2' : ''}`}
                            >
                                <input type="number" min="1" max="99"
                                    name={option.id}
                                    onChange={(event) => handleChangeFurniture(event, option.id)}
                                    value={priceFurniture[option.id] || ''}
                                    pattern="[1-9][0-9]*"
                                    onKeyPress={(event) => {
                                        if (event.key === '0' && event.target.value.length === 0) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}