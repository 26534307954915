import React, { useEffect, useRef, useState } from "react";
import { getLocale } from "../../helpers/Api";
import './SelectCity.scss';


export function SelectCity({ miasto, setMiasto, selectedMiasto, setSelectedMiasto }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        getLocale().then((data) => {
            setMiasto(data);
        });
    }, [setMiasto, setSelectedMiasto]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectChange = (selectedValue) => {
        setSelectedMiasto(selectedValue);
        setIsOpen(false);
      };


    return (
        <div className="block-form__form__left__osobiste">
            <h5>Miasto <span>(wymagane)</span></h5>
            <div className="miasto-dropdown" ref={dropdownRef}>
                <div className="dropdown-header" onClick={handleToggleDropdown}>
                    <div className='dropdown-header__subtitle'>{selectedMiasto.name ? selectedMiasto.name : 'Wroclaw'}</div>
                    <span className={`city__svg ${!isOpen ? 'city__svg--up' : 'city__svg--down'}`}>
                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 2L8 8L2 2" stroke="#31465A" strokeWidth="3" />
                        </svg>
                    </span>
                </div>
                {isOpen && (
                <ul className="dropdown-list">
                    {miasto.map((city) => (
                        <li
                            key={city.id}
                            value={city.name}
                            onClick={() => handleSelectChange(city)}
                            className={selectedMiasto.name === city.name ? 'selected' : ''}
                        >
                            {city.name}
                        </li>
                    ))}
                </ul>
            )}
            </div>
        </div>
    )
}
