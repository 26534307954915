import React from "react";

export function WindowCleaningIcon() {
    return (
        <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="wind-stroke wind-stroke1" d="M36.9802 39.2805C37.1602 39.4631 37.4042 39.5656 37.6586 39.5656C37.913 39.5656 38.157 39.4631 38.337 39.2805L51.0602 26.222C51.229 26.0364 51.3203 25.7914 51.3147 25.5388C51.3092 25.2862 51.2073 25.0456 51.0306 24.8678C50.8539 24.6899 50.616 24.5888 50.3672 24.5855C50.1184 24.5823 49.8782 24.6773 49.697 24.8505L36.9802 37.909C36.8023 38.0916 36.7026 38.338 36.7026 38.5947C36.7026 38.8515 36.8023 39.0979 36.9802 39.2805Z" fill="#2D4664" stroke="#2D4664" />
            <path className="wind-stroke wind-stroke2" d="M36.609 31.1616C36.7889 31.3442 37.033 31.4467 37.2874 31.4467C37.5418 31.4467 37.7857 31.3442 37.9658 31.1616L43.7258 25.3116C43.8945 25.126 43.9858 24.881 43.9803 24.6284C43.9747 24.3758 43.8729 24.1352 43.6961 23.9574C43.5194 23.7796 43.2815 23.6784 43.0328 23.6751C42.784 23.6719 42.5437 23.7669 42.3626 23.9401L36.6026 29.7901C36.4255 29.9735 36.327 30.2204 36.3281 30.4772C36.3294 30.7339 36.4303 30.9799 36.609 31.1616Z" fill="#2D4664" stroke="#2D4664" />
            <path className="wind-stroke wind-stroke3" d="M53.94 10.0303H11.06C10.8053 10.0303 10.5611 10.133 10.3811 10.3158C10.2011 10.4987 10.1 10.7467 10.1 11.0053V21.0153C9.05676 22.6143 3.36073 31.4803 3.37993 35.5753C3.37993 39.7808 6.33033 43.2453 10.1 43.7588V55.2053C10.1 55.4638 10.2011 55.7119 10.3811 55.8947C10.5611 56.0776 10.8053 56.1803 11.06 56.1803H53.94C54.1946 56.1803 54.4387 56.0776 54.6187 55.8947C54.7988 55.7119 54.9 55.4638 54.9 55.2053V11.0053C54.9 10.7467 54.7988 10.4987 54.6187 10.3158C54.4387 10.133 54.1946 10.0303 53.94 10.0303ZM5.29993 35.5688C5.29993 32.8778 8.89676 26.5533 11.0088 23.1798C13.1464 26.5338 16.8136 32.8258 16.82 35.5298C16.82 39.0268 14.26 41.8803 11.06 41.8933C7.89836 41.8803 5.31273 39.0528 5.29993 35.5688ZM12.02 43.7588C15.8152 43.2453 18.7592 39.7353 18.74 35.5038C18.74 31.6038 13.3448 23.1993 12.02 21.2038V11.9803H31.22V54.2303H12.02V43.7588ZM52.98 54.2303H33.14V11.9803H52.98V54.2303Z" fill="#2D4664" stroke="#2D4664" />
            <path className="wind-stroke wind-stroke4" d="M6.06666 30.5094V5.49146H59.5281V60.2951H6.06666V40.5993C6.06666 40.4047 6.0288 40.212 5.9552 40.0319L4.85822 37.3475L4.55945 34.979L5.97885 31.0151C6.03696 30.8528 6.06666 30.6818 6.06666 30.5094Z" stroke="#2D4664" strokeWidth="3" strokeLinejoin="round" />
        </svg>
    )
}