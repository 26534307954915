import React from "react";
import { Modal } from './Modal.jsx';

export const ModalPrivacyPolicy = ({ isOpenModal, setIsOpenModal, handleCloseModal }) => {
    return (
        <Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
            <div className="reglament">
                <h1>POLITYKA PRYWATNOŚCI SERWISU BIALOKLINING.PL</h1>
                <ul className="reglament__list">
                    <li className="reglament__item">
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej https://bialoklining.pl oraz poczty elektronicznej jest Biało Sp. z o.o., adres siedziby: ul. Rynek 60, 50-116 Wrocław, wpisana do rejestru przedsiębiorców pod numerem KRS: 0000888068, NIP: 8971889434, REGON: 388370740, adres poczty elektronicznej: bialoklining@gmail.com dalej „Administrator”.</li>
                            <li className="reglament__item-descr">W razie jakichkolwiek wątpliwości związanych z przetwarzaniem Państwa danych, prosimy o kontakt pod adresem: bialoklining@gmail.com lub pisemnie na adres siedziby Administratora.</li>
                            <li className="reglament__item-descr">
                                Państwa dane osobowe przetwarzamy w celu:
                                <ul>
                                    <li>zapewnienia funkcjonalności strony internetowej i ułatwienia korzystania z serwisu (art. 6 ust. 1 lit. b i f RODO); szczegóły na temat wykorzystania plików cookie na końcu informacji,</li>
                                    <li>realizacji umów z klientami (art. 6 ust. 1 lit. b RODO),</li>
                                    <li>jeśli ma to zastosowanie – prowadzenia rozliczeń, księgowości i sprawozdawczości finansowej (art. 6 ust. 1 lit. c i f RODO),</li>
                                    <li>realizacji obowiązków wynikających z przepisów prawa, w szczególności prawa telekomunikacyjnego i ustawy o świadczeniu usług drogą elektroniczną (art. 6 ust. 1 lit. c RODO).</li>
                                    <li>realizacji uzasadnionego interesu Administratora, polegającego na marketingu produktów i usług (art. 6 ust. 1 lit. f RODO),</li>
                                    <li>w celach wskazanych w treści zgód na przetwarzanie danych osobowych – jeśli takie zgody były wyrażane (art. 6 ust. 1 lit. a RODO).</li>
                                </ul>
                            </li>
                            <li className="reglament__item-descr">
                                Dane osobowe przetwarzamy także w związku z realizacją pozostałych uzasadnionych interesów administratora, na podstawie art. 6 ust. 1 lit. f RODO:
                                <ul>
                                    <li>w celu ustalenia, dochodzenia i obrony roszczeń,</li>
                                    <li>w celach statystycznych, związanych z poprawą efektywności pracy, jakości świadczonych usług i dostosowaniem ich do odbiorców.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Co do zasady, przetwarzamy dane podawane przez Państwo. Jeśli nie podawali nam Państwo swoich danych, to ich źródłem jest podmiot, który dysponował wyrażoną przez Państwa zgodą na ich udostepnienie na rzecz Administratora, lub inną ważną podstawą prawną. W tym przypadku, pozyskane dane osobowe obejmują dane niezbędne do prowadzenia danego rodzaju działań marketingowych (typowo, są to imię, nazwisko, adres e-mail, nr telefonu i/lub adres korespondencyjny).</li>
                            <li className="reglament__item-descr">Państwa dane osobowe co do zasady nie będą przekazywane poza Europejski Obszar Gospodarczy (dalej: EOG). Mając jednak na uwadze usługi świadczone przez podwykonawców Administratora przy realizacji wsparcia dla usług teleinformatycznych oraz infrastruktury IT, Administrator może zlecać wykonanie określonych czynności bądź zadań informatycznych uznanym podwykonawcom działającym poza EOG co może powodować przekazanie Państwa danych poza obszar EOG.</li>
                            <li className="reglament__item-descr">Państwa odbiorców spoza EOG, zgodnie z decyzją Komisji Europejskiej zapewniają odpowiedni stopień ochrony danych osobowych zgodny ze standardami EOG. W przypadku odbiorców na terytorium Państw nieobjętych decyzją Komisji Europejskiej, w celu zapewnienia odpowiedniego stopnia tej ochrony, Administrator zawiera umowy z odbiorcami Państwa danych osobowych, w oparciu o standardowe klauzule umowne wydane przez Komisję Europejską zgodnie z art. 46 ust.2lit.cRODO.</li>
                            <li className="reglament__item-descr">Kopię standardowych klauzul umownych można uzyskać od Administratora, zwracając się pod dane kontaktowe podane powyżej. Zastosowany przez Administratora sposób zabezpieczenia Państwa danych jest zgodny z zasadami przewidzianymi w rozdziale V RODO. Mogą Państwo zażądać dalszych informacji o stosowanych zabezpieczeniach w tym zakresie, uzyskać kopię tych zabezpieczeń oraz informację o miejscu ich udostępnienia.</li>
                            <li className="reglament__item-descr">
                                Odbiorcami Państwa danych osobowych mogą być:
                                <ul>
                                    <li>Firmy prowadzące działania marketingowe</li>
                                    <li>Firmy świadczące usługi lub dostarczające rozwiązania informatyczne</li>
                                    <li>Dostawcy i podwykonawcy Administratora.</li>
                                    <li>Firmy archiwizujące i niszczące dokumenty (w przypadku dokumentów w wersji papierowej, powiązanych z realizacją celów przetwarzania)</li>
                                    <li>Firmy świadczące usługi kurierskie i pocztowe (w przypadku korespondencji powiązanej z realizacją celów przetwarzania).</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Państwa dane osobowe będą przechowywane do czasu wycofania zgody lub wniesienia sprzeciwu, tj. okazania nam w dowolny sposób, że nie życzą sobie Państwo pozostawać z nami w kontakcie i otrzymywać informacji o podejmowanych przez nas działaniach. Po wycofaniu zgody lub wyrażeniu sprzeciwu, dane osobowe mogą być przechowywane na potrzeby wykazywania prawidłowości spełnienia obowiązków prawnych spoczywających na Administratorze lub do czasu upływu terminów przedawnienia roszczeń, w zależności, który termin jest dłuższy. W przypadku zawarcia z Administratorem umowy, dane osobowe będą przetwarzane przez czas trwania umowy, a po jej zakończeniu do upływu terminów przedawnienia wynikających z niej roszczeń.</li>
                            <li className="reglament__item-descr">Przysługuje Państwu prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia, wycofania zgody oraz wniesienia sprzeciwu wobec ich przetwarzania.</li>
                            <li className="reglament__item-descr">Jeśli uznają Państwo, że przetwarzanie danych osobowych Państwa dotyczących narusza przepisy RODO, przysługuje Państwu prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</li>
                            <li className="reglament__item-descr">Podanie danych osobowych jest dobrowolne, przy czym niezbędne do realizacji celów przetwarzania wskazanych powyżej.</li>
                            <li className="reglament__item-descr">Państwa dane nie będą przedmiotem zautomatyzowanego podejmowania decyzji powodującej skutki prawne lub podobnie istotny wpływ.</li>

                        </ul>
                    </li>
                    <li className="reglament__item">
                        Hosting
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: cyber_Folks S.A.</li>
                            <li className="reglament__item-descr">Dane rejestrowe firmy hostingowej: cyber_Folks S.A. z siedzibą w Poznaniu, Franklina Roosevelta 22, 60-829 Poznań, wpisana do Krajowego Rejestru Sądowego przez Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000685595, REGON 367731587, NIP 7792467259, kapitał zakładowy 283.600,00 zł w pełni wpłacony.</li>
                            <li className="reglament__item-descr">Pod adresem https://cyberfolks.pl/ możesz dowiedzieć się więcej o hostingu i sprawdzić politykę prywatności firmy hostingowej.</li>
                            <li className="reglament__item-descr">Firma hostingowa:
                                <ul>
                                    <li>stosuje środki ochrony przed utratą danych (np. macierze dyskowe, regularne kopie bezpieczeństwa),</li>
                                    <li>stosuje adekwatne środki ochrony miejsc przetwarzania na wypadek pożaru (np. specjalne systemy gaśnicze),</li>
                                    <li>stosuje adekwatne środki ochrony systemów przetwarzania na wypadek nagłej awarii zasilania (np. podwójne tory zasilania, agregaty, systemy podtrzymania napięcia UPS),</li>
                                    <li>stosuje środki fizycznej ochrony dostępu do miejsc przetwarzania danych (np. kontrola dostępu, monitoring),</li>
                                    <li>stosuje środki zapewnienia odpowiednich warunków środowiskowych dla serwerów jako elementów systemu przetwarzania danych (np. kontrola warunków środowiskowych, specjalistyczne systemy klimatyzacji),</li>
                                    <li>stosuje rozwiązania organizacyjne dla zapewnienia możliwie wysokiego stopnia ochrony i poufności (szkolenia, wewnętrzne regulaminy, polityki haseł itp.),</li>
                                    <li>powołała Inspektora Ochrony Danych.</li>
                                </ul>
                            </li>
                            <li className="reglament__item-descr">
                                Firma hostingowa w celu zapewnienia niezawodności technicznej prowadzi logi na poziomie serwera. Zapisowi mogą podlegać:
                                <ul>
                                    <li>zasoby określone identyfikatorem URL (adresy żądanych zasobów – stron, plików),</li>
                                    <li>czas nadejścia zapytania,</li>
                                    <li>czas wysłania odpowiedzi,</li>
                                    <li>nazwę stacji klienta – identyfikacja realizowana przez protokół HTTP,</li>
                                    <li>informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP</li>
                                    <li>adres URL strony poprzednio odwiedzanej przez użytkownika (referer link) – w przypadku gdy przejście do Serwisu nastąpiło przez odnośnik,</li>
                                    <li>informacje o przeglądarce użytkownika,</li>
                                    <li>informacje o adresie IP,</li>
                                    <li>informacje diagnostyczne związane z procesem samodzielnego zamawiania usług poprzez rejestratory na stronie,</li>
                                    <li>informacje związane z obsługą poczty elektronicznej kierowanej do Operatora oraz wysyłanej przez Operatora.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        Pliki cookie
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Serwis gromadzi w sposób automatyczny wyłącznie informacje zawarte w plikach cookie. Operator serwisu informuje, iż Pliki cookie (tzw. „ciasteczka”) to dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu. Cookie zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
                            <li className="reglament__item-descr">
                                Pliki cookie wykorzystywane są w celu:
                                <ul>
                                    <li>dostosowania zawartości stron internetowych Serwisu do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie użytkownika serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;</li>
                                    <li> tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
                                    <li>utrzymania sesji użytkownika serwisu</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        Google Analytics i Google Tag Manager
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">
                                Google przetwarza informacje w celu oceny korzystania z witryny przez użytkownika, tworzenia raportów dotyczących ruchu na stronach oraz świadczenia innych usług na rzecz operatora witryny związanych z ruchem na witrynach internetowych i korzystaniem z Internetu.
                            </li>
                            <li className="reglament__item-descr">
                                Celem przetwarzania danych jest ocena korzystania ze strony internetowej i tworzenie raportów dotyczących aktywności na stronie. W oparciu o korzystanie ze strony internetowej i Internetu będą świadczone inne powiązane usługi. Przetwarzanie opiera się na prawnie uzasadnionym interesie operatora strony.
                            </li>
                            <li className="reglament__item-descr">
                                Google Analytics gromadzi dane o adresach IP, lokalizacji sieci, dacie wizyty, systemie operacyjnym, typie przeglądarki, odwiedzanych stronach, spędzonym czasie, a także interakcjach i profilu. Możesz zapobiec przechowywaniu plików cookie poprzez odpowiednie ustawienie oprogramowania przeglądarki; jednak pamiętaj, że jeśli to zrobisz, możesz nie być w stanie w pełni wykorzystać wszystkich funkcji tej witryny.
                            </li>
                        </ul>
                    </li>
                </ul>
                <button className="btn-close-modal" onClick={handleCloseModal}>
                    Akceptuje
                </button>
            </div>
        </Modal>
    )
}