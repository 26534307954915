import React from "react";
import { Modal } from './Modal.jsx';

export const ModalReglament = ({ isOpenModal, setIsOpenModal, handleCloseModal }) => {
    return (
        <Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
            <div className="reglament">
                <h1>Regulamin świadczenia usług drogą elektroniczną przez Biało Sp. z o.o.</h1>
                <ul className="reglament__list">
                    <li className="reglament__item">
                        I. PRZEDMIOT UMOWY
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Niniejsze Ogólne Warunki Świadczenia Usług (zwane dalej „Ogólnymi Warunkami”) stanowią integralną część umowy o świadczenie usług sprzątania (zwanej dalej „Umową”) zawartej pomiędzy Bialoklining.pl a Klientem. Każda osoba korzystająca z Serwisu Usługodawcy zobowiązana jest do przestrzegania postanowień Regulaminu.
                            </li>
                            <li className="reglament__item-descr">Pod użytymi w niniejszym Regulaminie korzystania z serwisu Bialoklining.pl pojęciami rozumie się:
                                <ul>
                                    <li>„Regulamin” – to niniejszy Regulamin świadczenia usług elektronicznych przez Biało Sp. z o.o.;</li>
                                    <li>„Usługodawca” – Administratorem danych jest Biało Sp. z o.o. z siedzibą we Wrocławiu , wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia Fabrycznej we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000888068, REGON: 388370740, NIP: 8971889434 (zwana dalej „Biało Sp. z o.o.” lub „Usługodawca”);</li>
                                    <li>„Serwis” – internetowy serwis, prowadzony przez Usługodawcę pod aktualnym adresem https://bialoklining.pl/ który służy do zawierania Umów pomiędzy Biało Sp. z o.o. a Klientem,</li>
                                    <li>„Użytkownik” – każdy podmiot korzystający z Internetu, który posiada zdolność prawną i zdolność do czynności prawnych w zakresie niezbędnym do skutecznego i ważnego zawarcia umowy o świadczenie Usługi drogą elektroniczną na warunkach przewidzianych w Regulaminie;</li>
                                    <li>„Cena sprzątania” – to cena za wykonanie Sprzątania.</li>
                                    <li>„Osoba Sprzątającą” – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, faktycznie wykonująca usługi sprzątania,</li>
                                    <li>„Klient” – konsument, osoba fizyczna prowadząca działalność gospodarczą oraz osoby prawne, które zawarły Umowę o świadczenie Usługi za pośrednictwem Serwisu,</li>
                                    <li>„Ustawa” – ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r. Nr 144 poz. 1204 z póź. zm.);</li>
                                    <li>„Pomieszczenie” – oznacza lokal(mieszkalny albo biurowy), którego posprzątanie Zlecający zleca Sprzątaczowi;</li>
                                    <li>„Zamówienie” – to zamówienie przez Zlecającego przeprowadzenia przez Osobę Sprzątającą usługi sprzątania we wskazanym Pomieszczeniu/-ach za podaną Ceną Sprzątania we wskazanym zakresie czynności.</li>
                                    <li>„Zlecający” – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, korzystająca z Serwisu, ofertująca podjęcie przez Osobę Sprzątająca sprzątania Pomieszczenia.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        II. RODZAJE USŁUG
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Bialoklining.pl świadczy na rzecz Zlecających następujące rodzaje usług sprzątania:
                                <ul>
                                    <li>sprzątanie STANDARDOWE o zakresie podstawowym: wycieranie drzwi na mokro i sucho, usuwanie kurzu z powierzchni otwartych i łatwo dostępnych, mycie frontów szaf i szafek, mycie mebli i sprzętów AGD z zewnątrz, odkurzanie i mycie podłóg bez odsuwania mebli, mycie parapetów wewnętrznych i grzejników, mycie luster, czyszczenie zlewu, umywalki, wanny, kabiny prysznicowej, muszli klozetowej, bidetu, odkamienianie kabiny prysznicowej, mycie i polerowanie armatury, mycie blatów, kuchenki/płyty indukcyjnej, glazury kuchennej, odkurzanie mebli tapicerowanych, odkurzanie dywanów i wykładzin, wstawienie brudnych naczyń do zmywarki, opróżnienie kosza na śmieci, usunięcie pajęczyn, przetarcie kontaktów, profesjonalne środki czystości i sprzęt, sprzątanie maksymalnie do 2m. Zleceniodawca jest zobowiązany do zapewnienia dostępu do wody i prądu, który umożliwi wykonanie usługi. Usługa dotyczy standardowego stanu zabrudzenia.</li>
                                    <li>sprzątanie GRUNTOWNE (PO WYNAJMIE) o zakresie rozszerzonym: wycieranie drzwi na mokro i sucho, usuwanie kurzu z powierzchni otwartych i łatwo dostępnych, mycie frontów i góry szaf i szafek, mycie mebli i sprzętów AGD z zewnątrz, odkurzanie i mycie podłóg z odsuwaniem mebli, mycie parapetów wewnętrznych i grzejników, mycie luster, czyszczenie zlewu, umywalki, wanny, kabiny prysznicowej, muszli klozetowej, bidetu, odkamienianie kabiny prysznicowej, mycie glazury łazienkowej bez doczyszczania fug, mycie i polerowanie armatury, mycie blatów, kuchenki/płyty indukcyjnej, glazury kuchennej, odkurzanie mebli tapicerowanych, odkurzanie dywanów i wykładzin, wstawienie brudnych naczyń do zmywarki, opróżnienie kosza na śmieci, usunięcie pajęczyn, przetarcie kontaktów, profesjonalne środki czystości i sprzęt, sprzątanie maksymalnie do 3m. . Zleceniodawca jest zobowiązany do zapewnienia dostępu do wody i prądu, który umożliwi wykonanie usługi.</li>

                                </ul>
                            </li>
                            <li className="reglament__item-descr">Cena Usługi jest określona w Serwisie i jest płatna z góry.</li>
                            <li className="reglament__item-descr">Cena zależy od metrażu zakresu sprzątania (STANDARDOWE, GUNTOWNE itd), częstotliwość usługi.</li>
                            <li className="reglament__item-descr">Ceny pokazuje system w formularzu zamówienia przy wyborze odpowiednich opcji zamówienia.</li>
                            <li className="reglament__item-descr">Zlecająca może wybrać opcję dodatkowe, które są dodatkowo płatne:
                                <ul>
                                    <li>usługi dodatkowe - ceny zależą od rodzaju sprzętu AGD albo, w przypadku szaf i szafek, lokalizacji, cena pokazuje się w formularzu zamówienia po podaniu odpowiednich danych.</li>
                                    <li>mycie okien – ceny zależą od rodzaju okien , cena pokazuje się w formularzu zamówienia po podaniu odpowiednich danych</li>
                                    <li>czyszczenie mebli tapicerowanych i wykładzin dywanowych - ceny zależą od rodzaju mebli albo metrażu wykładziny, cena pokazuje się w formularzu zamówienia po podaniu odpowiednich danych.</li>
                                </ul>
                            </li>
                            <li className="reglament__item-descr">
                                Zlecający ma prawo do zmiany terminu lub godziny świadczenia Usługi do 48 godzin przed pierwotnie zaplanowanym czasem realizacji usługi.
                            </li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        III. ZAWARCIE UMOWY
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Umowa zawierana jest w formie elektronicznej za pośrednictwem Serwisu, przy użyciu przeglądarki internetowej. Zlecająca nie jest zobowiązany do posiadania certyfikowanego bezpiecznego podpisu elektronicznego. Bezpieczeństwo połączenia z Serwisem zapewnione jest poprzez szyfrowanie danych przy wykorzystaniu certyfikatu SSL.</li>
                            <li className="reglament__item-descr">Zlecający za pośrednictwem Serwisu zawiera Umowę o świadczenie Usługi ze spółką jest Biało sp. z o.o. z siedzibą we Wrocławiu , wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia Fabrycznej we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000888068, REGON: 388370740, NIP: 8971889434 (zwana dalej „Biało” lub „Usługodawca”). podczas składania zamówienia zobowiązany jest podać prawdziwe informacje. Składanie zamówienia przez Klienta jest dokonywane zgodnie z pouczeniami Serwisu.</li>
                            <li className="reglament__item-descr">Zlecający składa Zamówienie na wykonanie Usługi, w którym określa:
                                <ul>
                                    <li>rodzaj Usługi,</li>
                                    <li>termin i godzina wykonania Usługi,</li>
                                    <li>miejsce wykonania Usługi,</li>
                                    <li>m2 Pomieszczenie.</li>
                                </ul>
                            </li>
                            <li className="reglament__item-descr">Biało Sp. z o.o. potwierdza Zlecającemu warunki wykonania Usługi w potwierdzeniu Zamówienia przesyłanym drogą elektroniczną. Do każdej Usługi przypisany jest indywidualny numer Zamówienia.</li>
                            <li className="reglament__item-descr">Składając Zamówienie Zlecający potwierdza zapoznanie się i zaakceptowanie Ogólnych Warunków oraz wyraża zgodę na przetwarzanie jego danych w celu wykonania usługi.</li>
                            <li className="reglament__item-descr">Umowa jest zawierana na czas wykonania Usługi. Minimalna kwota zamówienia: 200zł</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        IV. WARUNKI WYKONANIA USŁUGI
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Biało Sp. z o.o. wykonuje Usługę korzystając z własnych środków czystości oraz sprzętów, chyba że Zlecający zażyczy inaczej.</li>
                            <li className="reglament__item-descr">Godziny wykonywania Usług są następujące: 7.00 – 21.00 od poniedziałku do niedzieli</li>
                            <li className="reglament__item-descr">Usługi są realizowane na terenie Wrocławia i 15 km okolic.</li>
                            <li className="reglament__item-descr">Zlecająca zobowiązuje się zapewnić bezpieczne i higieniczne warunki pracy do realizacji Sprzątania.</li>
                            <li className="reglament__item-descr">W przypadku, gdy w miejscu świadczenia Usługi Usługodawca uzna, że nie ma zapewnionych bezpiecznych i higienicznych warunków pracy albo stwierdzi, iż jest obiektem napaści słownej, bądź fizycznej, świadczenie Usługi będzie natychmiast przerwane, a Klient zostanie obciążony karą umowną w wysokości ceny jednokrotnej wizyty, w kwocie, za którą miała zostać wykonana usługa.</li>
                            <li className="reglament__item-descr">W przypadku gdy Zlecająca zamówił usługę, ale z jego powodu nie została ona zrealizowana i nie zmienił jej czas realizacji maksymalnie 48h przed usługa, nie jest uprawniony do zwrotu kosztów za usługę.</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        V. PŁATNOŚCI
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">
                                Zlecający ma prawo do wyboru jednej z form płatności bezgotówkowych za Usługę:
                                <ul>
                                    <li>płatność przelewem bankowym.</li>
                                    <li>płatność kartą kredytową.</li>
                                </ul>
                            </li>
                            <li className="reglament__item-descr">Operatorem kart płatniczych jest PayTel SA z siedziba w Warszawie (02-092), przy ulicy Żwirki i Wigury 14, zarejestrowanej w Sądzie Rejonowym dla m. st. Warszawy w Warszawie, XIII Wydział Krajowego rejestru Sądowego, pod numerem 0000332228, NIP: 527-260-66-18, REGON141802150.</li>
                            <li className="reglament__item-descr">Zlecający mają prawo żądana wystawienia faktury bez VAT za zakupioną Usługę. Faktura w formie elektronicznej w formacie PDF jest przesyłana na adres poczty elektronicznej Zlecający podany w Zamówieniu.</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        VI. REKLAMACJE
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Zlecający mają prawo składać reklamacje w sprawach dotyczących realizacji Usługi.</li>
                            <li className="reglament__item-descr">Jeżeli Zlecająca jest nieusatysfakcjonowany z terminowości lub jakości wykonania Usługi, ma prawo złożenia reklamacji do 48 godzin po zakończeniu usługi sprzątania. Reklamacja może być złożona w formie elektronicznej na adres: bialoklining@gmail.com. Reklamacja powinna zawierać uzasadnienie i ewentualnie dowody na nienależyte wykonanie Usługi np. fotografie opatrzone datą i godziną.</li>
                            <li className="reglament__item-descr">Biało Sp. z o.o. zobowiązuje się do rozpatrzenia reklamacji w terminie 14 dni i zawiadomienia Zlecająca o sposobie rozpatrzenia reklamacji.</li>
                            <li className="reglament__item-descr">W przypadku pozytywnego rozpatrzenia reklamacji Biało Sp. z o.o. ponownie wykona usługę, polegającą na jednokrotnej wizycie, w zakresie reklamowanej usługi, w terminie uzgodnionym z Zlecającym, jednakże nieprzekraczającym 30 dni od rozpatrzeni reklamacji.</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        VII. ODPOWIEDZIALNOŚĆ ZA SZKODY
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Biało Sp. z o.o. ponosi odpowiedzialność za szkody powstałe w mieniu Zlecający podczas wykonywania Usługi.</li>
                            <li className="reglament__item-descr">Biało Sp. z o.o. oświadcza, że posiada ubezpieczenie od odpowiedzialności cywilnoprawnej, które obejmuje szkody powstałe w mieniu Zlecający podczas świadczenia Usług. Suma ubezpieczenia wynosi 200.000,- (dwieście tysięcy) złotych.</li>
                            <li className="reglament__item-descr">W przypadku powstania szkody w mieniu Zlecający podczas wykonywania Usługi, Zlecająca winien złożyć reklamację oraz współpracować z Biało Sp. z o.o. podczas procesu likwidacji szkody, w szczególności udzielać informacji, składać wymagane przez ubezpieczyciela dokumenty i udostępnić miejsca świadczenia Usług do oględzin.</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        VIII. OCHRONA DANYCH OSOBOWYCH, POLITYKA PRYWATNOŚCI
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Usługodawca zapewnia ochronę powierzonych mu przez Usługobiorców danych zgodnie z Polityką Prywatności.</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        IX. ODSTĄPIENIE OD UMOWY
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Zlecający nie mają prawa do odstąpienia od umowy, może natomiast przesunąć termin sprzątania do 48h przed zamówioną usługą.</li>
                            <li className="reglament__item-descr">W przypadku, gdy Zlecająca zrezygnuje z wykonania Usługi w trakcie jej trwania, zobowiązany jest do zapłaty za całą zamówioną Usługę.</li>
                        </ul>
                    </li>
                    <li className="reglament__item">
                        X. PRZEPISY KOŃCOWE
                        <ul className="reglament__list-subtitle">
                            <li className="reglament__item-descr">Niniejsze Ogólne Warunki wchodzą w życie z dniem 13.01.24 r</li>
                            <li className="reglament__item-descr">Biało Sp. z o.o. zastrzega sobie prawo do zmiany lub uzupełnienia Ogólnych Warunków. Zmiana Ogólnych Warunków wchodzi w życie z dniem opublikowania na stronie Serwisu. W przypadku Umów zawartych przed datą publikacji zmiany Ogólnych Warunków stosuje się warunki dotychczasowe.</li>
                            <li className="reglament__item-descr">Niniejsze Ogólne Warunki oraz Umowa podane są pod właściwość prawa polskiego.</li>
                            <li className="reglament__item-descr">W razie sporu na tle stosunku prawnego wynikającego z Umowy Biało Sp. z o.o. i Zlecający będą dążyć do polubownego zakończenia sporu. W przypadku braku osiągnięcia porozumienia spór będzie poddany pod właściwość sądu powszechnego według miejsca świadczenia Usługi.</li>
                            <li className="reglament__item-descr">Postanowienia Ogólnych Warunków mają charakter rozłączny, a uznanie któregokolwiek z nich za nieważne, nie uchybia mocy wiążącej pozostałych.</li>
                            <li className="reglament__item-descr">W związku z zapewnieniem najwyższej jakości usługi, Biało Sp. z o.o. dba o to, aby osoby sprzątające, realizujące usługę utrzymania czystości, były odpowiednio wyszkolone w zakresie swoich obowiązków oraz przejawiały wysoką kulturę osobistą. Zgodnie z dobrymi zasadami współpracy Zleceniodawca nie powinien proponować pracownikom Biało Sp. z o.o. bezpośredniej współpracy, poza obowiązującą obie strony umową.</li>
                        </ul>
                    </li>
                </ul>
                <button className="btn-close-modal" onClick={handleCloseModal}>
                    Akceptuje
                </button>
            </div>
        </Modal>
    )
}