import reference from '../../assets/images/IMG_4524.webp';
import './Reference.scss';

export const Reference = () => {
    return (
        <section className="reference">
            <div className="container">
                <div className="reference__wrapp">
                    <h4>Referecje</h4>
                    <div className="reference__wrapp-info">
                        
                        <div className='reference-img'>
                            <div className='back-reference-img'></div>
                            <img src={reference} alt="reference" />
                        </div>
                       
                        <span>Jesteśmy dumni, że możemy zaoferować Państwu nasze zasoby, wysoką jakość usług i dbałość o szczegóły.</span>
                    </div>
                </div>
            </div>
        </section>
    )
}