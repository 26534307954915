import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../Datepicker/Datepicker.scss';
import pl from "date-fns/locale/pl";
import { useEffect } from "react";
import { getOrderDate } from "../../helpers/Api";

export function Datepicker({ onDateChange, selectedDate, setSelectedDate }) {
    const [selectedTimes, setSelectedTimes] = useState([]);
    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2); // заказать услугу можно не ранее, чем через три дня
    const maxDate = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate()); // услугу можно заказать на три месяца вперёд
    const excludedHours = [0, 1, 2, 3, 4, 5, 6, 7, 9, 11, 13, 15, 17, 18, 19, 20, 21, 22, 23];
    const excludeTimes = excludedHours.map(hour => new Date(0, 0, 0, hour, 0));

    const [reservedTimes, setReservedTimes] = useState([]);

    useEffect(() => {
        getOrderDate().then((data) => {
          const groupedData = data.reduce((acc, { date, date_time, order_id, id }) => {
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push({ id: id, time: date_time, disabled: order_id === 0 });
            return acc;
          }, {});
      
          const disabledTimes = Object.entries(groupedData).map(([date, times]) => {
            const countMap = times.reduce((count, { time }) => {
              count[time] = (count[time] || 0) + 1;
              return count;
            }, {});
      
            return times.map(({ time, disabled, id }) => ({
              date,
              time,
              disabled: countMap[time] > 1 || disabled,
              id,
            }));
          });
      
          setReservedTimes(disabledTimes);
        });
      }, []);

    const excludedTimes = reservedTimes.reduce((acc, times) => {
        times.forEach((time) => {
            const date1 = new Date(selectedDate).toLocaleString().slice(0, 10)
            const date2 = new Date(time.date).toLocaleString().slice(0, 10)
            if (selectedDate && date1 === date2 && time.disabled) {
                const hour = parseInt(time.time.split(":")[0]);
                acc.push(new Date(0, 0, 0, hour, 0));
            }
        });
        return acc;
    }, [...excludeTimes]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        onDateChange(date);
    };

    const handleTimeChange = (time) => {
        if (selectedTimes.includes(time)) {
            return;
        }
        setSelectedTimes([...selectedTimes, time]);
    };

    // const timeClassName = (time) => {
    //   const isBookedTime = excludedTimes.some(excludedTime =>
    //     time.getHours() === excludedTime.getHours() &&
    //     time.getMinutes() === excludedTime.getMinutes()
    //   );
    //   if (!selectedDate) {
    //     return "disabled-time";
    //   }
    //   return isBookedTime ? 'disabled-time' : 'undisabled-time';
  
    // }

    return (
        <div className="block-form__form__left__osobiste">
            <h5>Wybierz termin <span>(wymagane)</span></h5>
            <div className="data-czas">
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd.MM.yyyy"
                    minDate={minDate}
                    maxDate={maxDate}
                    locale={pl}
                    timeCaption="godzina"
                    showTimeSelect
                    inline
                    shouldCloseOnSelect={false}
                    timeIntervals={60}
                    excludeTimes={excludedTimes}
                    timeClassName={() => {
                        if (!selectedDate) {
                            return "disabled-time";
                        }
                    }}
                    // timeClassName={timeClassName}
                    onSelect={handleTimeChange}
                    disabled={!selectedDate}
                />
            </div>
        </div>

    );
};