import React from 'react';
import hand from '../../assets/images/handshake.svg';
import oc from '../../assets/images/noun-insurance.svg';
import medal from '../../assets/images/medal.svg';
import payment from '../../assets/images/payment.svg';
import './WhyWe.scss';

export const WhyWe = () => {
    return (
        <div className='section-we'>
            <div className='container'>
                <ul className="section-we__list">
                    <li className="section-we__item">
                        <div className="section-we__img">
                            <img src={hand} alt="hand" />
                        </div>
                        <span className="section-we__descr">
                            Z nami będzie łatwo się dogadać
                        </span>
                    </li>
                    <li className="section-we__item">
                        <div className="section-we__img">
                            <img src={oc} alt="OC" />
                        </div>
                        <span className="section-we__descr">
                            Posiadamy ubezpieczenie OC
                        </span>
                    </li>
                    <li className="section-we__item">
                        <div className="section-we__img">
                            <img src={medal} alt="medal" />
                        </div>
                        <span className="section-we__descr">
                            Jakościowo wykonujemy swoją pracę
                        </span>
                    </li>
                    <li className="section-we__item">
                        <div className="section-we__img">
                            <img src={payment} alt="payment" />
                        </div>
                        <span className="section-we__descr">
                            Wygodny sposób płatności
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}