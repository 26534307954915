import React from 'react';

export const BlueMarker = () => {
    return (
        <svg className='blue-marker' xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="4.5" fill="#4280BB" stroke="#2D4664" />
        </svg>
    )
}

export const WhiteMarker = () => {
    return (
        <svg className='white-marker' xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="4.5" stroke="#2D4664" />
        </svg>
    )
}