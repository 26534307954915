import axios from "axios";

export const getDodatkowe = async () => {
    const response = await axios.get(`/api/dodatkowe`);
    return response.data;
}

export const getWindows = async () => {
    const response = await axios.get(`/api/windows`);
    return response.data;
}

export const getFurniture = async () => {
    const response = await axios.get(`/api/meble`);
    return response.data;
}

export const getLocale = async () => {
    const response = await axios.get(`/api/locale`);
    return response.data;
}

export const getSprzatanie = async () => {
    const response = await axios.get(`/api/sprzatanie`);
    return response.data;
}

// export const getOrderInfo = async() => {
//     const response = await axios.get(`/api/orderinfo`);
//     return response.data;
// }

export const getOrderDate = async () => {
    const response = await axios.get(`/api/orderdate`);
    return response.data;
}

// export const getOrder = async() => {
//     const response = await axios.get(`/api/order`);
//     return response.data;
// }

export const postOrder = async (form) => {
    const response = await axios.post(`/api/create-order`, form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
}

export const postOrderCompanies = async (form) => {
    const response = await axios.post(`/api/feedback`, form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
}

export const getComment = async () => {
    const response = await axios.get(`https://mybusiness.googleapis.com/v4/accounts/4238303082880870008/locations/reviews`,
    {
        headers: {
            // 'Content-Type': null ,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            'Accept': "application/json, text/plain, */*",
        }
    }
    );
    return response;
}


export const postPromocodeGet = async (body) => {
    const response = await axios.post("/api/promocode-get", body);
    return response;
}

export const postFindUser = async (body) => {
    const response = await axios.post("/api/find-user", body);
    return response;
}