import React from 'react';
import './RelyOnUs.scss';

export const RelyOnUs = () => {
    return (
        <div className='rely'>
            <div className='container'>
                <div className='relay__wrapp'>
                    <div className='relay__wrapp-left'>
                        <h4>Na nas można polegać:</h4>
                        <span>Przed rozpoczęciem pracy wszystko starannie planujemy, dzielimy na etapy. Ustalamy priorytety.</span>
                        <span>Dlatego zawsze wykonujemy pracę na czas, ponieważ przestrzeganie podstawowych warunków umowy jest gwarancją sukcesu.</span>
                    </div>
                    <div className='relay__wrapp-right'></div>
                </div>
            </div>
        </div>
    )
}
