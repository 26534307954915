import React, { useState } from "react";
import budowie from '../../assets/images/Po-budowie.svg';
import maszynowe from '../../assets/images/maszynowe.svg';
import remoncie from '../../assets/images/Po-remonte.svg';
import okien from '../../assets/images/Okien.svg';
import utrzymanie from '../../assets/images/utrzymanie.svg';
import './UslugiTab.scss';

export const UslugiTab = () => {
    const [activeTab, setActiveTab] = useState(1); // устанавливаем начальный активный таб

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <section className="section-uslugi">
            <div className="container">
                <h4>Usługi</h4>
                <div className="tabs-wrapp">
                    <div className="tabs">
                        <div
                            className={activeTab === 1 ? "tab active-tab" : "tab"}
                            onClick={() => handleTabClick(1)}
                        >
                            Sprzątanie po budowie
                        </div>
                        <div
                            className={activeTab === 2 ? "tab active-tab" : "tab"}
                            onClick={() => handleTabClick(2)}
                        >
                            Czysczenie maszynowe
                        </div>
                        <div
                            className={activeTab === 3 ? "tab active-tab" : "tab"}
                            onClick={() => handleTabClick(3)}
                        >
                            Sprzątanie po remoncie
                        </div>
                        <div
                            className={activeTab === 4 ? "tab active-tab" : "tab"}
                            onClick={() => handleTabClick(4)}
                        >
                            Mycie okien
                        </div>
                        <div
                            className={activeTab === 5 ? "tab active-tab" : "tab"}
                            onClick={() => handleTabClick(5)}
                        >
                            UTRZYMANIE CZYSTOŚCI
                        </div>
                    </div>
                    <div className="tab-content-wrapp">
                        {activeTab === 1 && (
                            <div className="tab-content">
                                <span>Kompletne sprzątanie  pomieszczeń po pracach budowlanych, odkurzanie, doczyszczanie podłóg i posadzek. Czyszczenie wszelkich powierzchni z pozostałości materiałów budowlanych. Wyniesienie odpadów budowlanych i gruzu.</span>
                                <div><img src={budowie} alt="" /></div>
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div className="tab-content">
                                <span>Mycie maszynowe posadzek garaży, centrów logistycznych, zakładów produkcyjnych, parkingów, pomieszczeń przemysłowych spożywczych i farmaceutycznych, obiektów szkolnych i sportowych, magazynów, mycie i doczyszczenie powierzchni klatek schodowych, czyszczenie wykładzin różnego rodzaju</span>
                                <div><img src={maszynowe} alt="" /></div>
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div className="tab-content">
                                <span>Oczyszczenie wszystkich powierzchni z pyłu pobudowlanego, wyniesienie śmieci, usuwanie resztek kleju,zaschniętej farby, czy pozostałości po zaprawach.</span>
                                <div className="po-remonte"><img src={remoncie} alt="" /></div>
                            </div>
                        )}
                        {activeTab === 4 && (
                            <div className="tab-content">
                                <span>Mycie okien i ram (plastykowych, drewnianych, aluminiowych), szklanych balustrad i luster. Czyszcenie witryn, banerów reklamowych, przeszklień każdego rodzaju oraz  mycie paneli fotowoltaicznych.</span>
                                <div><img src={okien} alt="" /></div>
                            </div>
                        )}
                        {activeTab === 5 && (
                            <div className="tab-content">
                                <span className="utrzymanie">
                                    - sprzątanie biur, lokali handlowych, obiektów gastronomicznych, przemysłowych, magazynów i td. <br/>
                                    - usługi cykliczne i jednorazowe
                                </span>
                                <div><img src={utrzymanie} alt="" /></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};