import React, { useEffect, useState } from 'react';
import './Footer.styles.scss';
import Viber from '../../assets/images/viber.svg';
import Insta from '../../assets/images/Insta.svg';
import Whatsapp from '../../assets/images/whatsapp.svg';
import FaceBook from '../../assets/images/Facebook.svg';
import Phone from '../../assets/images/phone.svg';
import Email from '../../assets/images/email.svg';
import logo from '../../assets/images/logo-footer.svg';

const Footer = () => {

    const [currentYear, setCurrentYear] = useState(null);

    useEffect(() => {
        const year = new Date().getFullYear();
        setCurrentYear(year);
    }, []);

    return (
        <footer id='kontakt'>
            <div className='container'>
                <div className='footer__wrapp'>
                    <div className='footer__left'>
                        <img src={logo} alt="logo" />
                        <a href='https://psfs.com.pl/' target='__blank' className='footer__psfs-wrapp'>
                            <div className='psfs'>
                                <span>PSFS</span>
                            </div>
                            <div className='psfs-text'>
                                <span>POLSKIE STOWARZYSZNIE</span>
                                <span>FIRM SPRZĄTAJĄCYCH</span>
                            </div>
                        </a>
                        <div className='footer__copyright'>
                            <span>BIAŁO KLINING {currentYear}</span>
                            <span>Wszelkie prawa zastrzeżone</span>
                        </div>
                    </div>
                    <div className='footer__right'>
                        <h4>KONTAKT</h4>
                       <div className='footer__right-wrapp'>
                            <div className='footer__right-center'>
                                <ul className="footer__info">
                                    <li>Biało spółka z ograniczoną odpowiedzialnością z siedzibą ul.Rynek 60, 50-116 Wrocław</li>
                                    <li>NIP: 8971889434</li>
                                    <li>REGON: 388370740</li>
                                    <li>KRS: 0000888068</li>
                                </ul>
                                <ul className='footer__info-list'>
                                    <li className="footer__right-item">
                                        <a href={"tel:+48730858170"} className="footer__link">
                                            <img src={Phone} alt="Phone" />
                                            <span>+48730858170</span>
                                        </a>
                                    </li>
                                    <li className="footer__right-item">
                                        <a href={"mailto:bialoklining@gmail.com"} className="footer__link">
                                            <img src={Email} alt="Email" />
                                            <span>bialoklining@gmail.com</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul className='footer__right-list'>
                                <li className="footer__right-item">
                                    <a href={"viber://chat?number=%2B48730858170"} className="footer__link">
                                        <img src={Viber} alt="Viber" />
                                       <span>Viber</span>                                    
                                    </a>
                                </li>
                                <li className="footer__right-item">
                                    <a href={"https://api.whatsapp.com/send/?phone=48730858170"} target='__blank' className="footer__link">
                                        <img src={Whatsapp} alt="WA" />
                                        <span>WhatsApp</span>
                                    </a>
                                </li>
                                <li className="footer__right-item">
                                    <a href='https://m.facebook.com/klining.wroclaw/' target='__blank' className="footer__link">
                                        <img src={FaceBook} alt="FB" />
                                        <span>Facebook</span>
                                    </a>
                                </li>
                                <li className="footer__right-item">
                                    <a href='https://www.instagram.com/bialo_klining?igsh=MXZ2emIzNjhuejE5dQ==' target='__blank' className="footer__link">
                                        <img src={Insta} alt="Insta" />
                                        <span>Instagram</span>
                                    </a>
                                </li>
                            </ul>
                       </div>
                    </div>
                    <div className='footer-back-img'></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;