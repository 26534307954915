import HeaderContent from '../components/HeaderContent/HeaderContent';
import { WhyWe } from '../components/WhyWe/WhyWe';
import Onas from '../components/Onas/Onas';
import Pracujemy from '../components/Pracujemy/Pracujemy';
import { RelyOnUs } from '../components/RelyOnUs/RelyOnUs';
import Header from '../components/Header/Header';
import { Opinion } from '../components/Opinion/Opinion';
import { SubmissionForm } from '../components/Form/Form';

export const HomePages = () => {
    return (
        <>
            <Header />
            <HeaderContent />
            <WhyWe />
            <Onas />
            <SubmissionForm />
            <Pracujemy />
            <RelyOnUs />
            <Opinion />
        </>
    )
}