import React from 'react';
import './Layout.styles.scss';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';

export function Layout() {
    return (
        <div className="app-layout">
            <Outlet />
            <Footer />
        </div>

    )
}