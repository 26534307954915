import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Form.style.scss';
import { AdditionalServices } from './AdditionalServices/AdditionalServices';
import { PremisesCleaning } from './PremisesCleaning/PremisesCleaning';
import { WindowCleaning } from './WindowCleaning/WindowCleaning';
import { FurnitureCleaning } from './FurnitureCleaning/FurnitureCleaning';
import { SelectCity } from './SelectCity/SelectCity';
import { ClientData } from './ClientData/ClientData';
import { Datepicker } from './Datepicker/Datepicker';
import { postFindUser, postOrder, postPromocodeGet } from '../helpers/Api';
import { ModalReglament } from '../Modal/ModalReglament';
import { ModalPrivacyPolicy } from '../Modal/ModalPrivacyPolicy';
import { Modal } from '../Modal/Modal';


export const SubmissionForm = () => {
  const [miasto, setMiasto] = useState([]);
  const [selectedMiasto, setSelectedMiasto] = useState({ id: 1, name: 'Wroclaw' });
  const [priceOne, setPriceOne] = useState('');
  const [premisesCleaning, setPremisesCleaning] = useState([]);
  const [dodatkowe, setDodatkowe] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [priceOkno, setPriceOkno] = useState({});
  const [windows, setWindows] = useState([]);
  const [priceFurniture, setPriceFurniture] = useState({});
  const [furniture, setFurniture] = useState([]);
  const [changeUslugiFirstBlock, setChangeUslugiFirstBlock] = useState('');
  // const [messSend, setMessSend] = useState(false);
  // const [messSendMistake, setMessSendMistake] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredCheckboxes = {};

  for (const key in checkboxes) {
    if (checkboxes[key]) {
      filteredCheckboxes[key] = checkboxes[key] ? 1 : 0;
    }
  }

  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    yourAddress: '',
    phone: '',
    email: '',
    pocztowy: '',
    informacja: '',
  });

  const resetForm = () => {
    setFields({
      firstName: '',
      lastName: '',
      yourAddress: '',
      phone: '',
      email: '',
      pocztowy: '',
      informacja: '',
    });

    setSelectedMiasto({ id: 1, name: 'Wroclaw' });
    setPriceFurniture({});
    setPriceOkno({});
    setCheckboxes((prevState) => {
      const clearedCheckboxes = { ...prevState };
      for (const key in clearedCheckboxes) {
        clearedCheckboxes[key] = false;
      }
      return clearedCheckboxes;
    });
    setChangeUslugiFirstBlock('');
    setPriceOne('');
    setSelectedDate(null);
  };

  const setField = (fieldName, value) => {
    setFields((prevFields) => ({ ...prevFields, [fieldName]: value }));
  };

  const summDodat = () => {
    let summ = 0;
    dodatkowe.forEach(option => {
      if (checkboxes[option.id]) {
        summ += Number(option.price);
      }
    });
    return summ;
  };

  const summSecond = () => {
    let summ = 0;
    windows.forEach((option) => {
      const value = priceOkno[option.id] || 0;
      summ += value * option.price;
    });
    return summ;
  };

  const summThird = () => {
    let summ = 0;
    furniture.forEach((option) => {
      const value = priceFurniture[option.id] || 0;
      summ += value * option.price;
    });
    return summ;
  }

  const changeServices = () => {
    // Ищем объект с соответствующим id в premisesCleaning
    const selectedService = premisesCleaning.find((service) => service.id === 2); // Для return 9 (id: 2, price: 60)
    if (!changeUslugiFirstBlock) {
      // Проверяем, существует ли объект, прежде чем обращаться к свойству price
      if (selectedService) {
        return Number(selectedService.price); // Преобразуем price в число и возвращаем его
      } else {
        // Если объект не найден, возвращаем значение по умолчанию
        return 0; // Или любое другое значение по умолчанию, которое вам нужно
      }
    } else {
      // Ищем объект с соответствующим id для return 12 (id: 3, price: 70)
      const selectedService = premisesCleaning.find((service) => service.id === 3);
      if (selectedService) {
        return Number(selectedService.price);
      } else {
        return 0; // Здесь также можно вернуть значение по умолчанию
      }
    }
  };

  const summFirst = () => {
    let summ = 0;
    // Ищем объект с соответствующим id для let sale = 0.02 (id: 1, price: 2)
    const selectedSale = premisesCleaning.find((sale) => sale.id === 1);
    let price = changeServices();
    if (priceOne <= 20) {
      summ = priceOne * price;
    } else {
      if (priceOne > 200) {
        summ = priceOne * 5;
      } else {
        // Проверяем, существует ли объект selectedSale, прежде чем использовать его свойство price
        if (selectedSale) {
          summ = priceOne * (price - priceOne * (Number(selectedSale.price)));
        } else {
          summ = priceOne * price; // Или используем значение price по умолчанию
        }
      }
    }
    return summ;
  };

  const baseTotal = summFirst() + summDodat() + summSecond() + summThird();

  let priceSumm = () => {
    // return summFirst() + summDodat() + summSecond() + summThird();
    // const baseTotal = summFirst() + summDodat() + summSecond() + summThird();

    if (discountPhone > 0 && discount > 0) {
      const totalDiscountAmount = baseTotal * ((discount + discountPhone) / 100);
      const discountedTotal = baseTotal - totalDiscountAmount;
      return discountedTotal;
    }

    if (discount > 0) {
      const discountAmount = baseTotal * (discount / 100);
      const discountedTotal = baseTotal - discountAmount;
      return discountedTotal;
    }

    if (discountPhone > 0) {
      const discountAmount = baseTotal * (discountPhone / 100);
      const discountedTotal = baseTotal - discountAmount;
      return discountedTotal;
    }

    return baseTotal;
  }


  const dataMain = [];

  for (let i = 0; i <= 30; i++) {
    dataMain.push(i);
  }

  let mailPaslugi = () => {
    let mailText = '';

    if (!changeUslugiFirstBlock) {
      mailText += `Sprzątanie standardowe;<br />`;
    } else {
      mailText += `Sprzątanie gruntowne (po wynajmie);<br />`;
    }

    if (priceOne && priceOne !== '0') {
      mailText += `powierzchnia: ${priceOne} m2<br />`;
    }

    dodatkowe.forEach(option => {
      if (checkboxes[option.id]) {
        mailText += `${option.name}<br />`;
      }
    });

    windows.forEach((option) => {
      const value = priceOkno[option.id] || 0;
      if (value !== 0) {
        mailText += `${option.name}: ${value} szt<br />`
      }
    });

    furniture.forEach((option) => {
      const value = priceFurniture[option.id] || 0;
      if (value !== 0 && option.name !== 'Wykładzina dywanowa') {
        mailText += `<div> ${option.name}: ${value} szt</div>`
      } else if (value !== 0 && option.name === 'Wykładzina dywanowa') {
        mailText += `<div> ${option.name}: ${value} m2</div>`
      }
    });

    return (
      mailText
    )
  }

  let paslugiForm = () => {
    let mainText = '';

    if (!changeUslugiFirstBlock) {
      mainText += `<div>Sprzątanie standardowe</div>`;
    } else {
      mainText += `<div>Sprzątanie gruntowne (po wynajmie)</div>`;
    }

    if (priceOne && priceOne !== '0') {
      mainText += `<div>powierzchnia: ${priceOne} m2<div>`;
    }

    furniture.forEach((option) => {
      const value = priceFurniture[option.id] || 0;
      if (value !== 0 && option.name !== 'Wykładzina dywanowa') {
        mainText += `<div> ${option.name}: ${value} szt</div>`
      } else if (value !== 0 && option.name === 'Wykładzina dywanowa') {
        mainText += `<div> ${option.name}: ${value} m2</div>`
      }
    });

    windows.forEach((option) => {
      const value = priceOkno[option.id] || 0;
      if (value !== 0) {
        mainText += `<div> ${option.name}: ${value} szt</div>`
      }
    });

    dodatkowe.forEach(option => {
      if (checkboxes[option.id]) {
        mainText += `<div>${option.name}</div>`;
      }
    });

    return (
      <div dangerouslySetInnerHTML={{ __html: mainText }} />
    )
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeAgree = () => {
    setIsChecked(!isChecked);
  };

  const validateEmail = () => {
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (pattern.test(fields.email)) {
      return true;
    } else {
      return false;
    }
  }

  const validateForm = () => {
    if (baseTotal >= 120 && isChecked && selectedDate !== null && new Date(selectedDate).toLocaleTimeString('pl') !== '00:00:00' && validateEmail() && fields.phone !== '' && fields.firstName !== '' && fields.yourAddress !== '') {
      return 1;
    } else {
      return 0;
    }
  }

  const formMess = () => {
    let formMessVar = '';
    // if (messSend) {
    //   formMessVar = 'Wiadomość wysłana. Skontaktujemy się z Tobą wkrótce.';
    // } else if (messSendMistake) {
    //   formMessVar = 'Wybrany termin jest niedostępny, proszę wybrać inny termin.';
    // } else {
    //   if (validateForm()) {
    //     formMessVar = 'mogę wysłać';
    //   } else {
    //     formMessVar = 'wypełnij pola';
    //   }
    // }
    if (validateForm()) {
      formMessVar = 'mogę wysłać';
    } else {
      formMessVar = 'wypełnij pola';
    }
    return formMessVar;
  }

  const mamyChasy = () => {
    if (priceSumm() <= 1000) return Math.round(priceSumm() / 120);
    if (priceSumm() <= 2000) return Math.round(priceSumm() / 180);
    if (priceSumm() > 1000) return Math.round(priceSumm() / 240);
  }

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen501, setErrorModalOpen501] = useState(false);
  const handleCloseErrorModal = () => setErrorModalOpen(false);
  const handleCloseError501Modal = () => setErrorModalOpen501(false);
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    window.location.reload();
  };

  const date = new Date(selectedDate); // текущая дата
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // добавляем ведущий ноль, если месяц < 10
  const day = String(date.getDate()).padStart(2, '0'); // добавляем ведущий ноль, если день < 10
  const formattedDate = `${year}-${month}-${day}`; // форматированная дата в строковом виде
  const sendMail = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('locale_id', selectedMiasto.id)
    formData.append('firstname', fields.firstName)
    formData.append('lastname', fields.lastName)
    formData.append('adress', fields.yourAddress)
    formData.append('phone', fields.phone)
    formData.append('email', fields.email)
    formData.append('kod_index', fields.pocztowy)
    formData.append('notes', fields.informacja)
    formData.append('mebel_id', JSON.stringify(priceFurniture))
    formData.append('okien_id', JSON.stringify(priceOkno))
    formData.append('sprzatanie_id', JSON.stringify({ [changeUslugiFirstBlock === 1 ? 3 : 2]: priceOne }))
    formData.append('dodatkowe_id', JSON.stringify(filteredCheckboxes))
    formData.append('date', formattedDate)
    formData.append('date_time', new Date(selectedDate).toLocaleTimeString())
    formData.append('order_time', mamyChasy());
    formData.append('total', Math.round(priceSumm()));
    formData.append('promocode_id', promocodeId);
    formData.append('promo_discount', discount);
    // formData.append('personal_discount', discountPhone);
    formData.append('personal_discount', discountPhone === null ? 0 : discountPhone);

    if (validateForm()) {
      postOrder(formData).then((res) => {
        if (res.status === 200) {
          // setMessSend(true);
          // setMessSendMistake(false);
          setSuccessModalOpen(true);
          // setTimeout(() => {
          //   setMessSend(false);
          //   window.location.reload();
          // }, 2000)
          resetForm();
        }
      }
      )
        .catch(error => {
          if (error.response.status === 403) {
            setSelectedDate(null);
            // setMessSendMistake(true);
            setErrorModalOpen(true);
          } else {
            setErrorModalOpen501(true)
          }
        })
    }
  }


  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const [isOpenModalPrivacy, setIsOpenModalPrivacy] = useState(false);
  const handleOpenModalPrivacy = () => setIsOpenModalPrivacy(true);
  const handleCloseModalPrivacy = () => setIsOpenModalPrivacy(false);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  const [valuePromocode, setValuePromocode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [promocodeId, setPromocodeId] = useState(0);
  const [status, setStatus] = useState('');

  const [discountPhone, setDiscountPhone] = useState(0);

  useEffect(() => {
    if (fields.phone.length === 15) {
      postFindUser({
        phone: fields.phone
      }).then((res) => {
        setDiscountPhone(res.data.discount);
      })
        .catch(e => {
          if (e.response.status === 404 || e.response.status === 400) {
            setDiscountPhone(0)
            return
          }
        })
    } else if (fields.phone.length < 15) {
      setDiscountPhone(0)
    }
  }, [fields.phone]);

  const handleButtonPromocodeClick = () => {
    postPromocodeGet({
      name: valuePromocode
    }).then((res) => {
      setDiscount(res.data.discount);
      // setPromocodeName(res.data.name)
      setPromocodeId(res.data.id);
      setStatus(1)
    })
      .catch(e => {
        if (e.response.status === 404 || e.response.status === 400) {
          setStatus(2);
          setDiscount(0)
          return
        }
      })
  };


  const [scrollPosition, setScrollPosition] = useState(0);
  const [resultStyle, setResultStyle] = useState({});

  const uslugiRef = useRef(null);
  const resultRef = useRef(null);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useLayoutEffect(() => {
    const handleScrollPosition = () => {
      if (window.innerWidth <= 820) {
        
        // const resultTop = window.innerWidth === 820 ? '78' : window.innerWidth >= 768 ? '76' : window.innerWidth >= 540 ? '75' : window.innerWidth >= 360 ? '80' : '78';
        // const unit = 'vh';
        const resultMinusTop = window.innerWidth === 820 ? 900 : window.innerWidth >= 768 ? 800 : window.innerWidth >= 540 ? 600 : window.innerWidth >= 360 ? 650 : 600;
        if (uslugiRef.current && resultRef.current) {
          const uslugiPosition = uslugiRef.current.getBoundingClientRect().top + window.scrollY;
          const resultPosition = resultRef.current.getBoundingClientRect().top + window.scrollY - resultMinusTop;

          if (scrollPosition >= uslugiPosition && scrollPosition <= resultPosition) {
            setResultStyle({ display: 'flex' });
          } else {
            setResultStyle({ display: 'none' });
          }
        }
      }
    };

    handleScrollPosition();

    window.addEventListener('scroll', handleScrollPosition);

    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, [scrollPosition]);

  const handleScrollToResult = () => {
    const resultElement = document.getElementById('result');
    if (resultElement) {
      resultElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="block-form" id='uslugi'
     ref={uslugiRef}
     >
      <div className="container">
        <div className="block-form__h2">
          <h2>Złożyc zamówienie</h2>
        </div>
        <div className="block-form__form">
          <div className="block-form__form__left" id='uslugi-form'>
            <SelectCity
              miasto={miasto}
              setMiasto={setMiasto}
              selectedMiasto={selectedMiasto}
              setSelectedMiasto={setSelectedMiasto}
            />

            <div className="block-form__form__left__osobiste">
              <h5>Wybór usług <span>(wymagane)</span></h5>
              <PremisesCleaning
                premisesCleaning={premisesCleaning}
                setPremisesCleaning={setPremisesCleaning}
                priceOne={priceOne}
                setPriceOne={setPriceOne}
                changeUslugiFirstBlock={changeUslugiFirstBlock}
                setChangeUslugiFirstBlock={setChangeUslugiFirstBlock}
              />
              <AdditionalServices
                dodatkowe={dodatkowe}
                setDodatkowe={setDodatkowe}
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
              />
              <WindowCleaning
                windows={windows}
                setWindows={setWindows}
                setPriceOkno={setPriceOkno}
                priceOkno={priceOkno}
              />
              <FurnitureCleaning
                furniture={furniture}
                setFurniture={setFurniture}
                priceFurniture={priceFurniture}
                setPriceFurniture={setPriceFurniture}
              />
            </div>
            <Datepicker onDateChange={handleDateChange}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <ClientData {...fields} setField={setField} />
          </div>
          <div ref={resultRef}>
            <div className='block-price-summ' style={resultStyle} onClick={handleScrollToResult}>
                <span className='podsumowane'>Podsumowanie:</span>
                <span className='price-summ'>{Math.round(summFirst() + summDodat() + summSecond() + summThird())} pl</span>
            </div>
          </div>
          <div
            className="block-form__form__result"
            id="result"
            >
            <div className="block-form__form__result__block"
              id="form-result"
            >
              <h5>Podsumowanie:</h5>
              <form name="finish" className="__result__block__form" >
                <div className="__result__block__form__item form-wrapp-item-price">
                  <div></div>
                  <div className='form-item-price-ml'>
                    <div>Cena:</div>
                    {(discountPhone === 0 || discountPhone === null) && discount === 0 ? (
                      <div className="__item__content form-item-price">{Math.round(priceSumm())} pl</div>
                    ) : (
                      <div>
                        <div className="__item__content form-item-price form-item-price-old">{Math.round(summFirst() + summDodat() + summSecond() + summThird())} pl</div>
                        <div className="__item__content form-item-price">{Math.round(priceSumm())} pl</div>
                        {discount > 0 && <div className='discount-text'>Zniżka za kod promocyjny <span className='discount'>{discount}%</span></div>}
                        {discountPhone > 0 && <div className='discount-text'>Indywidualna zniżka <span className='discount'>{discountPhone}%</span></div>}
                      </div>
                    )}
                    <input type="hidden" name="total" value={Math.round(priceSumm())} />
                  </div>
                </div>
                <div className="__result__block__form__item">
                  <div>Miasto:</div>
                  <div className="__item__content">{selectedMiasto.name}</div>
                  <input type="hidden" name="locale_id" value={Number(selectedMiasto.id)} />
                </div>
                <div className="__result__block__form__item">
                  <div>Usługi:</div>
                  <div className="__item__content form-item-services">
                    {paslugiForm()}
                  </div>
                  {<input type="hidden" name="order_info" value={mailPaslugi()} />}
                </div>
                <div className="__result__block__form__item">
                  <div>Data:</div>
                  <div className="__item__content">{selectedDate ? new Date(selectedDate).toLocaleDateString('pl', options) : 'nie wybrano'}</div>
                  <input type="hidden" name="date" value={selectedDate ? new Date(selectedDate).toLocaleDateString('ru') : 'nie wybrano'} />
                </div>
                <div className="__result__block__form__item">
                  <div>Сzas:</div>
                  <div className="__item__content">{selectedDate &&
                    new Date(selectedDate).toLocaleTimeString('ru') !== '00:00:00' ?
                    new Date(selectedDate).toLocaleTimeString('ru') : 'nie wybrano'}</div>
                  <input type="hidden" name="date_time" value={selectedDate && new Date(selectedDate).toLocaleTimeString('ru') !== '00:00:00' ? new Date(selectedDate).toLocaleTimeString('ru') : 'nie wybrano'} />
                </div>
                <div className="__result__block__form__item">
                  <div>Imię:</div>
                  <div className="__item__content">{fields.firstName} {fields.lastName}</div>
                  <input type="hidden" name="firstname" value={fields.firstName} />
                  <input type="hidden" name="lastname" value={fields.lastName} />
                </div>
                <div className="__result__block__form__item">
                  <div>Adres:</div>
                  <div className="__item__content">{fields.yourAddress}</div>
                  <input type="hidden" name="adress" value={fields.yourAddress} />
                </div>
                <div className="__result__block__form__item">
                  <div>Telefon:</div>
                  <div className="__item__content">{fields.phone}</div>
                  <input type="hidden" name="phone" value={fields.phone} />
                </div>
                <div className="__result__block__form__item">
                  <div>Email:</div>
                  <div className="__item__content">{fields.email}</div>
                  <input type="hidden" name="email" value={fields.email} />
                </div>
                <div className="__result__block__form__item item-godzin">
                  <div>Przewidywany czas wykonywania usługi:</div>
                  <div className="__item__content">{mamyChasy()} gdz</div>
                  <input type="hidden" name="order_time" value={mamyChasy()} />
                </div>
                <input type="hidden" name="kod_index" value={fields.pocztowy} />
                <input type="hidden" name="notes" value={fields.informacja} />
                <div className="__block__form__btn promocode-wrapp">
                  {status === 2 && (
                    <span>Kod promocyjny jest nieprawidłowy!</span>
                  )}

                  {status === 1 && (
                    <span className='promocode-green-text'>Zniżką {discount}%</span>
                  )}
                  <input className={status === 1 ? 'promocode promocode-green' : (status === 2 ? 'promocode promocode-red' : 'promocode')}
                    value={valuePromocode.toUpperCase()}
                    onChange={(event) => setValuePromocode(event.target.value)}
                    type="text" name='promocyjny' placeholder='Kod promocyjny' />
                  <button type='button' onClick={handleButtonPromocodeClick} className={valuePromocode.length !== 0 ? 'promocode-btn' : 'promocode-btn-disabled'}>Użyj</button>
                </div>
                <div className="check">
                  <input className='check-form' type="checkbox" checked={isChecked} onChange={handleCheckboxChangeAgree} />
                  <span>Zapoznałem się z <button onClick={handleOpenModal} className='modal-btn-agree' type='button'>Regulaminem</button>
                    oraz <button onClick={handleOpenModalPrivacy} className='modal-btn-agree' type='button'>Polityką prywatności</button>
                    i akceptuję ich warunki</span>
                </div>
                <div className="__block__form__btn">
                  <input type="submit" onClick={sendMail} name="form-action" value="Wyślij" className={validateForm() ? 'submitAvalible' : 'submitDisables'} />
                </div>
                <div className="val-mess val-mess-min">
                  * Minimalne zamówienie od 120 pl
                </div>
                <div className="val-mess">{formMess()}</div>
              </form>
            </div>
          </div>
        </div>
        <div className='block-form__bottom' id='form-bottom'>
          <span>“Bardzo ważne są dla nas długotrwałe relacje”</span>
        </div>
        <div className='form-back-bottom'></div>
      </div>
      {errorModalOpen501 && (
        <div className='modal-form-wrapp'>
          <Modal isOpen={errorModalOpen501} setIsOpen={setErrorModalOpen501}>
            <div className='form-modal-text'>
              <span>Serwis jest tymczasowo niedostępny. Proszę spróbować ponownie później.</span>
              <button onClick={handleCloseError501Modal}>Dobrze</button>
            </div>
          </Modal>
        </div>
      )}
      {errorModalOpen && (
        <div className='modal-form-wrapp'>
          <Modal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
            <div className='form-modal-text'>
              <span>Wybrany termin jest niedostępny, proszę wybrać inny termin.</span>
              <button onClick={handleCloseErrorModal}>Dobrze</button>
            </div>
          </Modal>
        </div>
      )}
      {successModalOpen && (
        <div className='modal-form-wrapp'>
          <Modal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
            <div className='form-modal-text'>
              <span>Wiadomość wysłana. Skontaktujemy się z Tobą wkrótce.</span>
              <button className='form-modal-btn-success' onClick={handleCloseSuccessModal}>Dobrze</button>
            </div>
          </Modal>
        </div>
      )}
      <ModalReglament isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} handleCloseModal={handleCloseModal} />
      <ModalPrivacyPolicy isOpenModal={isOpenModalPrivacy} setIsOpenModal={setIsOpenModalPrivacy} handleCloseModal={handleCloseModalPrivacy} />
    </div>
  )
}
