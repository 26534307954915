import React, { useEffect, useState } from "react";
import { PremisesCleaningIcon } from "../../Icon/PremisesCleaningIcon";
import { getSprzatanie } from "../../helpers/Api";
import { BlueMarker, WhiteMarker } from "./MarkerIcon";

export function PremisesCleaning({ premisesCleaning, setPremisesCleaning, priceOne, setPriceOne, changeUslugiFirstBlock, setChangeUslugiFirstBlock }) {
  useEffect(() => {
    getSprzatanie().then((data) => {
      setPremisesCleaning(data);
    });
  }, [setPremisesCleaning]);

  const [showFirst, setShowFirst] = useState(true);
  const [showSecond, setShowSecond] = useState(false);

  const toggleShowShowFirst = () => {
    setShowFirst(!showFirst);
    setShowSecond(false);
    setChangeUslugiFirstBlock(0);
  };

  const toggleShowShowSecond = () => {
    setShowSecond(!showSecond);
    setShowFirst(false);
    setChangeUslugiFirstBlock(1);
  };

  useEffect(() => {
    // Set changeUslugiFirstBlock to 0 when the component mounts
    setChangeUslugiFirstBlock(0);
  }, [setChangeUslugiFirstBlock]);

  const isInputEmpty = priceOne !== '';

  return (
    <div className="__osobiste__block __block__yborka">
      <div className="__osobiste__block__left">
        {/* <div className="__osobiste__block__left__main __main__active"> */}
        <div className={`__osobiste__block__left__main ${isInputEmpty ? 'block__active' : ''}`}>
          <div className="osobiste__block-number">1</div>
          {isInputEmpty && (
            <div className="osobiste__block-v">
              <svg className="osobiste__block-v-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                <path d="M1.29394 4.50721C1.51473 4.28649 1.81414 4.1625 2.12634 4.1625C2.43853 4.1625 2.73795 4.28649 2.95874 4.50721L7.12192 8.6704L15.4459 0.345208C15.5552 0.235818 15.685 0.149031 15.8279 0.0897997C15.9707 0.0305688 16.1239 5.47404e-05 16.2785 7.35834e-08C16.4332 -5.45932e-05 16.5863 0.0303512 16.7292 0.0894811C16.8721 0.148611 17.0019 0.235307 17.1113 0.34462C17.2207 0.453932 17.3075 0.58372 17.3667 0.726573C17.426 0.869426 17.4565 1.02255 17.4565 1.17719C17.4566 1.33184 17.4262 1.48498 17.3671 1.62788C17.3079 1.77077 17.2212 1.90062 17.1119 2.01001L7.12192 12L1.29394 6.17202C1.07321 5.95123 0.949219 5.65181 0.949219 5.33961C0.949219 5.02742 1.07321 4.728 1.29394 4.50721Z" fill="white" />
              </svg>
            </div>
          )}
          <PremisesCleaningIcon />
          <div className="__osobiste__block__left__main__h">sprzątanie lokali</div>
        </div>
      </div>
      <div className="__osobiste__block__right sprzątanie-wrapp">
        {premisesCleaning.map((option) => {
          if (option.id === 1) {
            return (
              <div key={option.id} className="sprzątanie-top">
                <span>{option.name}:</span>
                <div className="sprzątanie-top-input-wrapp">
                  <input type="number" min="20" step="1" max="2000"
                    value={priceOne}
                    onChange={event => {
                      setPriceOne(event.target.value);
                    }}

                    pattern="[1-9][0-9]*"
                    onKeyPress={(event) => {
                      if (event.key === '0' && event.target.value.length === 0) {
                        event.preventDefault();
                      }
                    }}

                    onBlur={(event) => {
                      const inputValue = parseInt(event.target.value);
                      if (inputValue >= 1 && inputValue <= 19) {
                        setPriceOne(20);
                      } else {
                        setPriceOne(event.target.value);
                      }
                    }}
                  />
                  <p className="placeholder">m2</p>
                </div>
              </div>
            );
          } else if (option.id === 2 || option.id === 3) {
            return (
              <div className="sprzątanie-lokali" key={option.id}>
                <div
                  // onClick={() => setChangeUslugiFirstBlock(option.id === 2 ? 0 : 1)}
                  // onClick={event => setChangeUslugiFirstBlock(event.target.value = option.id === 2 ? 0 : 1)}
                  className={`sprzątanie-lokali__item${option.id === 3 ? ' sprzątanie-lokali__three' : (option.id === 2 ? ' sprzątanie-lokali__two' : '')}`}
                >
                  {option.id === 2 && (
                    <label className='radio__label'>
                      <input className='radio__input' type="radio" checked={showFirst} onChange={toggleShowShowFirst} />
                      <h6>{option.name}</h6>
                    </label>
                  )}
                  {option.id === 3 && (
                    <label className='radio__label'>
                      <input className='radio__input' type="radio" checked={showSecond} onChange={toggleShowShowSecond} />
                      <h6>{option.name}</h6>
                    </label>
                  )}
                </div>
              </div>
            );
          }
          return null;
        })}
        {changeUslugiFirstBlock === 0 && (
          <ul className="first-block__list">
            <li>
              <BlueMarker />
              <span>Wycieranie drzwi na mokro i sucho;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Usuwanie kurzu z powierzchni otwartych i łatwo dostępnych;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie frontów szaf i szafek do 2m wysokości;</span>
            </li>
            <li>
              <WhiteMarker />
              <span>Mycie frontów i góry szaf i szafek powyżej 2m;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie mebli i sprzętów AGD z zewnątrz;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie i mycie podłóg bez odsuwania mebli;</span>
            </li>
            <li>
              <WhiteMarker />
              <span>Odkurzanie i mycie podłóg z odsuwaniem mebli;</span>
            </li>
            <li>
              <WhiteMarker />
              <span>Usuwanie kurzu z opraw oświetleniowych i lamp sufitowych;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie parapetów wewnętrznych i grzejników;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie luster;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Czyszczenie zlewu, umywalki, wanny, kabiny prysznicowej, muszli klozetowej, bidetu;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkamienianie kabiny prysznicowej;</span>
            </li>
            <li>
              <WhiteMarker />
              <span>Mycie glazury łazienkowej (bez doczyszczania fug);</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie i polerowanie armatury;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie blatów, kuchenki/płyty indukcyjnej, glazury kuchennej;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie mebli tapicerowanych;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie dywanów i wykładzin;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Wstawienie brudnych naczyń do zmywarki;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Opróżnienie kosza na śmieci;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Usunięcie pajęczyn.</span>
            </li>
          </ul>
        )}
        {changeUslugiFirstBlock === 1 && (
          <ul className="first-block__list">
            <li>
              <BlueMarker />
              <span>Wycieranie drzwi na mokro i sucho;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Usuwanie kurzu z powierzchni otwartych i łatwo dostępnych;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie frontów szaf i szafek do 2m wysokości;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie frontów i góry szaf i szafek powyżej 2m;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie mebli i sprzętów AGD z zewnątrz;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie i mycie podłóg bez odsuwania mebli;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie i mycie podłóg z odsuwaniem mebli;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Usuwanie kurzu z opraw oświetleniowych i lamp sufitowych;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie parapetów wewnętrznych i grzejników;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie luster;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Czyszczenie zlewu, umywalki, wanny, kabiny prysznicowej, muszli klozetowej, bidetu;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkamienianie kabiny prysznicowej;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie glazury łazienkowej (bez doczyszczania fug);</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie i polerowanie armatury;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Mycie blatów, kuchenki/płyty indukcyjnej, glazury kuchennej;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie mebli tapicerowanych;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Odkurzanie dywanów i wykładzin;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Wstawienie brudnych naczyń do zmywarki;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Opróżnienie kosza na śmieci;</span>
            </li>
            <li>
              <BlueMarker />
              <span>Usunięcie pajęczyn.</span>
            </li>
          </ul>
        )}
      </div>
    </div >
  )
}
