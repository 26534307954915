import React, { useState } from 'react';
import './Header.styles.scss';
import appLogo from '../../assets/images/header-logo.png';
import menuOpenImg from '../../assets/images/menu-burger.png';
import menuCloseImg from '../../assets/images/menu-cross.png';
import Viber from '../../assets/images/viber.svg';
import Insta from '../../assets/images/Insta.svg';
import Whatsapp from '../../assets/images/whatsapp.svg';
import FaceBook from '../../assets/images/Facebook.svg';
import ModalMenu from "../ModalMenu/ModalMenu";
import AnchorLink from "react-anchor-link-smooth-scroll";

export const HeaderCompanies = () => {
    // const number = '+48 730 858 170';
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (
        <section className='header-section'>
            <header className={isMenuOpen ? 'back-color' : ''}>
                <div className='container'>
                    <div className='header-wrapper'>
                        <a href='/for-companies' className='header-logo-link'>
                            <img className='header-logo' src={appLogo} alt='app-logo' />
                        </a>
                        <img className='burger-menu' onClick={() => setIsMenuOpen(!isMenuOpen)} src={isMenuOpen ? menuCloseImg : menuOpenImg}
                            alt="burger" />
                        <div className='header-menu-wrapper'>
                            <ul className='header-menu-list'>
                                <li className='header-menu-item'><a href="/" className='anchor-link'>Dla osob prywatnych</a></li>
                                <li className='header-menu-item'><AnchorLink href="#kontakt" className='anchor-link'>Kontakt</AnchorLink></li>
                            </ul>
                        </div>
                        <ul className='social-list'>
                            <li className="social-list__item">
                                <a href={"viber://chat?number=%2B48730858170"} className='social-list__link'>
                                    <img src={Viber} alt="Viber" />
                                </a>
                            </li>
                            <li className="social-list__item">
                                <a href={"https://api.whatsapp.com/send/?phone=48730858170"} target='__blank' className='social-list__link'>
                                    <img src={Whatsapp} alt="WA" />
                                </a>
                            </li>
                            <li className="social-list__item">
                                <a href='https://m.facebook.com/klining.wroclaw/' target='__blank' className='social-list__link'>
                                    <img src={FaceBook} alt="FB" />
                                </a>
                            </li>
                            <li className="social-list__item">
                                <a href='https://www.instagram.com/bialo_klining?igsh=MXZ2emIzNjhuejE5dQ==' target='__blank' className='social-list__link'>
                                    <img src={Insta} alt="IG" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {isMenuOpen && <ModalMenu />}
            <div className='hero'>
               <div className='container'>
                    <div className='hero__container'>
                        <h2>Uslugi profesjonalnego sprząntania</h2>
                        <span>Sprzątamy obiekty o dowolnej powierzchni </span>
                        <button className="button" type="button">
                            <AnchorLink href="#skontaktuj">skontaktuj sie</AnchorLink>
                        </button>
                    </div>
               </div>
            </div>
        </section>
    );
};